import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import './App.scss';

import Clinician from "../../pages/Clinician";
import Home from "../../pages/Home";
import Troubleshooting from "../../pages/Troubleshooting";
import Downloads from "../../pages/Downloads";

class App extends Component {

    render() {
        return (
            <Router>
                <div className='app'>
                    <Route exact path={'/'} component={Home}/>
                    <Route path="/software/" component={Downloads}/>
                    <Route path="/connected/" component={Downloads}/>
                    <Route path="/provider/" component={Clinician}/>
                    <Route path="/troubleshooting/" component={Troubleshooting}/>
                    <Route path='/2d-provider' component={() => {
                        window.location.href = 'https://2d-provider.maskselector.com';
                        return null;
                    }}/>
                    <Route path='/2d' component={() => {
                        window.location.href = 'https://2d.maskselector.com';
                        return null;
                    }}/>
                </div>
            </Router>
        );
    }
}

export default App;
