import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import './Image.scss';

class Image extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
        this.handleMouseOver = this.handleMouseOver.bind(this);
    }

    handleClick(e) {
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    }

    handleMouseOut(e) {
        if (this.props.onMouseOut) {
            this.props.onMouseOut(e);
        }
    }

    handleMouseOver(e) {
        if (this.props.onMouseOver) {
            this.props.onMouseOver(e);
        }
    }

    render() {
        const { alt, className, height, id, src, style, width } = this.props;

        let classes = ClassNames({
            [ `${className}` ]: className
        });

        return (
            <img alt={alt}
                 className={classes}
                 height={height}
                 id={id}
                 src={src}
                 style={style}
                 onClick={this.handleClick}
                 onMouseOut={this.handleMouseOut}
                 onMouseOver={this.handleMouseOver}
                 width={width}
            />
        )
    }
}

Image.propTypes = {
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    height: PropTypes.number,
    id: PropTypes.string,
    onMouseOut: PropTypes.func,
    onMouseOver: PropTypes.func,
    onClick: PropTypes.func,
    src: PropTypes.string.isRequired,
    style: PropTypes.object,
    width: PropTypes.number
};

export default Image;
