import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './Downloads.scss';
import Lead from "../../components/Lead";
import Footer from "../../components/Footer";
import {version} from "../../version";

class Downloads extends Component {
    render() {
        return (
            <div className={'downloads'}>
                <Lead majorText={this.props.t("MaskSelector3D")} minorText={this.props.t("Software-Downloads")} heroClassName={'hero-downloads'}/>
                <div className={'site-content'}
                     style={{
                         backgroundColor: '#ffffff',
                         paddingTop: 30,
                         paddingBottom: 0,
                         paddingLeft: 15,
                         paddingRight: 15
                     }}>
                    <div className={'container'}>
                        <h3 className={'bold '} style={{textAlign: 'left'}}>{this.props.t("Download-MaskSelector3D")}</h3>
                        <div className={'text-left'}>
                            {this.props.t("MS3D-Download-Instructions")}
                        </div>
                        <div className={'d-flex flex-row-reverse'}
                             style={{
                                 backgroundColor: '#ffffff',
                                 paddingTop: 30,
                                 paddingBottom: 10,
                                 paddingLeft: 15,
                                 paddingRight: 15
                             }}>
                            <a href={`/downloads/${version["connected-3D"]}/MaskSelectorUnifiedInstaller.exe`}
                               className={'btn btn-primary'}>
                                {this.props.t("Download-MaskSelector3D")}
                            </a>
                        </div>
                        <div className={'d-flex flex-row-reverse'} style={{ paddingRight: 15 }}>
                            <a href={`/downloads/${version["connected-3D"]}/MaskSelectorUnifiedInstaller.sfv`}>{this.props.t("Simple-File-Verification")}</a>
                        </div>
                    </div>
                    <div className={'container'} >
                        <h3 className={'bold '} style={{textAlign: 'left'}}>{this.props.t("Before-Starting")} </h3>

                        <div className={'text-left'}
                             style={{
                                 backgroundColor: '#ffffff',
                                 paddingTop: 0,
                                 paddingBottom: 0,
                                 paddingRight: 15
                             }}>
                            {this.props.t("Before-Starting-Instructions")}
                        </div>
                        <div className={'d-flex flex-row-reverse'}
                             style={{
                                 backgroundColor: '#ffffff',
                                 paddingTop: 30,
                                 paddingBottom: 10,
                                 paddingLeft: 0,
                                 paddingRight: 15
                             }}>
                            <a href={'/downloads/Toolkit/MaskSelectorToolkitWPF.application'}
                               className={'btn btn-primary'}>
                                {this.props.t("Download-MST")}
                            </a>
                        </div>
                        <div className={'d-flex flex-row-reverse'} style={{ paddingRight:15,marginBottom: 30 }}>
                            <a href={'/downloads/Toolkit/MaskSelectorToolkitWPF.sfv'}>{this.props.t("Simple-File-Verification")}</a>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default withTranslation()(Downloads);
