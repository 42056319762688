import { BaseHttpClient, HttpClient } from "../http/HttpClient";

export interface IPdfClient {
    getPdf( url: string): Promise<any>;
}

class Pdf implements IPdfClient {
    constructor(private client: BaseHttpClient) {}

    async getPdf(url: string = "en-US"): Promise<any> {
        try {
            const options = { responseType: 'blob' }
            const response = await this.client.request(
                `https://s3.amazonaws.com/maskselector.com/intro/${url}/introduction.pdf`,
                options,
                "GET"
            );

            const pdfBlob = new Blob([response.data], {type: "application/pdf; charset=utf-8"})

            return pdfBlob;
        } catch (error: any) {
            return error;
        }
    }
}

export const PdfClient = new Pdf(HttpClient);
