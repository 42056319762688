import React, { Component } from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './HeaderBar.scss';

class HeaderBarItem extends Component {
    render() {
        const { className, route, style, text } = this.props;
        let classes = ClassNames({
            [`${className}`]: className,
        });

        return (
            <NavLink className={classes} style={style} activeClassName={'nav-item-active'} to={route}>
                {text}
            </NavLink>
        );
    }
}

HeaderBarItem.defaultProps = {
    className: 'nav-bar-item'
};

HeaderBarItem.propTypes = {
    className: PropTypes.string,
    route: PropTypes.string.isRequired,
    style: PropTypes.object,
    text: PropTypes.string.isRequired
};

export default HeaderBarItem;
