import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import './Copyright.scss';

class Copyright extends Component {
    render() {
        const { className, text, style } = this.props;

        let classes = ClassNames({
            [ `${className}` ]: className
        });

        return (
            <div className={classes} style={style}>
                &copy;&nbsp;{text}
            </div>
        );
    }
}

Copyright.defaultProps = {
    className: 'copyright',
    text: 'Koninklijke Philips N.V., 2004 - ' + (new Date()).getFullYear() + '. All rights reserved.'
};

Copyright.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    text: PropTypes.string.isRequired
};

export default Copyright;
