import React, { Component } from 'react';

import Image from '../Image';
import PRShape from '../../assets/images/MaskSelectorLogo.png';

import './Logo.scss';
import PropTypes from "prop-types";
import ClassNames from "classnames";

class Logo extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
        this.handleMouseOver = this.handleMouseOver.bind(this);
    }

    handleClick(e) {
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    }

    handleMouseOut(e) {
        if (this.props.onMouseOut) {
            this.props.onMouseOut(e);
        }
    }

    handleMouseOver(e) {
        if (this.props.onMouseOver) {
            this.props.onMouseOver(e);
        }
    }

    render() {
        const { className, height, style, width } = this.props;

        let classes = ClassNames({
            [ `${className}` ]: className
        });

        return (
            <div className="logo">
                <a href={'/'}>
                    <Image alt={'Philips Respironics Mask Selector'}
                           className={classes}
                           height={height}
                           id={'pr-logo'}
                           onClick={this.handleClick}
                           onMouseOut={this.handleMouseOut}
                           onMouseOver={this.handleMouseOver}
                           src={PRShape}
                           style={style}
                           width={width}/>
                </a>
            </div>
        );
    }
}

Image.propTypes = {
    className: PropTypes.string,
    height: PropTypes.number,
    onMouseOut: PropTypes.func,
    onMouseOver: PropTypes.func,
    onClick: PropTypes.func,
    src: PropTypes.string.isRequired,
    style: PropTypes.object,
    width: PropTypes.number
};

export default Logo;
