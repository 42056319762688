import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import SocialMediaBarItem from './SocialMediaBarItem';

import './SocialMediaBar.scss';

class SocialMediaBar extends Component {
    render() {
        const { className, style } = this.props;

        let classes = ClassNames({
            [ `${className}` ]: className
        });

        //TODO can take an array of these SocialMediaItems and iterate on it to make this more configurable.
        return (
            <div className={classes} style={style}>
                <div className='social-media-label'>{this.props.t("Connect-With-Philips")}</div>
                <SocialMediaBarItem icon={'youtube-square'} href={'https://www.youtube.com/user/PhilipsRespironics'}/>
                <SocialMediaBarItem icon={'twitter-square'} href={'https://twitter.com/PhilipsResp'}/>
                <SocialMediaBarItem icon={'facebook-square'} href={'https://www.facebook.com/PhilipsSleepWellness/'}/>
                <SocialMediaBarItem icon={'pinterest-square'} href={'http://www.pinterest.com/philipsglobal/'}/>
            </div>
        )
    }
}

SocialMediaBar.defaultProps = {
    className: 'social-media-bar',
    text: 'Connect with Philips'
};

SocialMediaBar.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
};

export default withTranslation()(SocialMediaBar);
