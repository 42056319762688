import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Logo from "../Logo";
import HeaderBarItem from "./HeaderBarItem";

import './HeaderBar.scss';

class HeaderBar extends Component {
    render() {
        return (
            <div className="header-bar">
                <Logo width={98} height={98}/>
                <div className={'header-bar-items'}>
                    <HeaderBarItem route={'/provider/'} text={this.props.t("Providers")}/>
                    <HeaderBarItem route={'/software/'} text={this.props.t("Software")}/>
                    <HeaderBarItem route={'/troubleshooting/'} text={this.props.t("Help")}/>
                </div>
            </div>
        );
    }
}

export default withTranslation()(HeaderBar);
