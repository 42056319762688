import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import "./Troubleshooting.scss";
import Lead from "../../components/Lead";
import Footer from "../../components/Footer";
import i18next from "i18next";
import { PdfClient } from "../../clients/pdf/pdfClient";

function Troubleshooting(props) {
  const [blob, setBlob] = useState(null);
  const [downloadFailed, setDownloadFailed] = useState(false);

  useEffect(() => {
    const resolveTranslation = i18next.resolvedLanguage;
    async function getBlob(url) {
      let pdf;

      try {
        pdf = await PdfClient.getPdf(url);
        setBlob(URL.createObjectURL(pdf));
      } catch (error) {
        await getDefault();
      }
    }

    async function getDefault() {
      let pdf;

      try {
        pdf = await PdfClient.getPdf("en_US");
        setBlob(URL.createObjectURL(pdf));
      } catch (error) {
        console.log("error");
      }
    }

    return getBlob(resolveTranslation);
  }, []);

  const toggleDownloadCheck = () => {
    if (!blob) setDownloadFailed(true);
    else setDownloadFailed(false);
  };

  return (
    <div>
      <div className={"troubleshooting"}>
        <Lead
          majorText={props.t("MaskSelector3D")}
          minorText={props.t("Help-And-Documentation")}
          heroClassName={"hero-troubleshooting"}
        />
        <div className={"site-content"}>
          <div className={"flex-grid container text-left"}>
            <div className={"flex-grid-box"}>
              <h3 className={"bold"}>{props.t("PC-Setup")}</h3>
              <div className={"troubleshooting-desc"}>
                {props.t("Windows-10-Help")}
              </div>
              <div className={"btn-flex bottom"}>
                <div>
                  <a
                    download={`3D-introduction.pdf`}
                    href={blob}
                    className={"btn btn-primary btn-troubleshooting"}
                    onClick={toggleDownloadCheck}
                  >
                    {props.t("Download")}
                  </a>
                </div>
              </div>
              {!blob && downloadFailed && (
                <div className={"download-failed-container"}>
                  <span className={"download-failed-text"}>
                    {props.t("Download-Failed")}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className={"container flex-column-padding"}>
            <h3
              className={"bold "}
              style={{
                textAlign: "left",
                paddingTop: 16,
                justifyContent: "flex-start",
              }}
            >
              {props.t("Still-Having-Trouble")}
            </h3>
            <div className={"text-left"}>{props.t("Issue-With-Guides")}</div>
            <div className={"contact-container"}>
              <div className={"call-us"}>
                <div>{props.t("Call-Us")}</div>
                <div>
                  <a href={"tel: 1-800-345-6443"} className="bold">
                    {props.t("Support-Phone") +
                      " " +
                      props.t("Support-Phone-Options")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default withTranslation()(Troubleshooting);
