import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import 'bootstrap/dist/css/bootstrap.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons'

import App from './components/App';
import "./index.scss";

import * as serviceWorker from './serviceWorker';

library.add(fab);

ReactDOM.render(<App/>, document.getElementById('root'));

serviceWorker.unregister();
