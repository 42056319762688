import React, { Component } from 'react';
import PropTypes from "prop-types";
import ClassNames from 'classnames';

import Copyright from "../Copyright";
import Shield from "../Shield";
import SocialMediaBar from "../SocialMediaBar";

import './Footer.scss';
import SiteSelector from "../SiteSelector";

class Footer extends Component {
    render() {
        const { className, style } = this.props;

        let classes = ClassNames({
            [ `${className}` ]: className
        });
        return (
            <footer className={classes} style={style}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-sm-4 align-self-center text-center'}>
                            <SocialMediaBar/>
                        </div>
                        <div className={'col-sm-4 text-center align-self-center'}>
                            <Shield style={{ margin: 'auto', maxWidth: 250 }}/>
                        </div>
                        <div className={'col-sm-4 text-center align-self-center'}>
                            <SiteSelector/>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-sm-12 text-center'}>
                            <Copyright/>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

Footer.defaultProps = {
    className: 'footer'
};

Footer.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
};

export default Footer;
