import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Lead from "../../components/Lead";
import Footer from "../../components/Footer";
import {version} from "../../version";
import "./Clinician.scss";

class Clinician extends Component {
    render() {
        return (
            <div>
                <div className={"home"}>
                    <Lead majorText={this.props.t("MaskSelector")}
                          minorText={this.props.t("For-Providers")}
                          heroClassName={"hero-clinician"}
                    />
                    <div className={"site-content"}
                         style={{
                             backgroundColor: "#ffffff",
                             paddingTop: 30,
                             paddingBottom: 0,
                             paddingLeft: 15,
                             paddingRight: 15,
                         }}>
                        <div className={"flex-row-container container"}>
                            <div className={"flex-3d-column"}>
                                <div className={"flex-column-padding "}>
                                    <h3 className={"bold "} style={{ textAlign: "left" }}>
                                    {this.props.t("MaskSelector3D")}
                                    </h3>
                                    <div className={"text-left"}>
                                        {this.props.t("MS3D-Download-Instructions")}
                                    </div>
                                    <div className={"btn-flex"}
                                         style={{
                                             backgroundColor: "#ffffff",
                                             paddingTop: 30,
                                             paddingBottom: 10,
                                             paddingRight: 15,
                                         }}>
                                        <a href={`/downloads/${version["connected-3D"]}/MaskSelectorUnifiedInstaller.exe`}
                                           className={"btn btn-primary"}>
                                            {this.props.t("Download-MaskSelector3D")}
                                        </a>
                                    </div>
                                    <div className={"btn-flex"} style={{ paddingRight: 15 }}>
                                        <a href={`/downloads/${version["connected-3D"]}/MaskSelectorUnifiedInstaller.sfv`}>
                                        {this.props.t("Simple-File-Verification")}
                                        </a>
                                    </div>
                                </div>
                                <div className={"flex-column-padding"}>
                                    <h3 className={"bold "} style={{ textAlign: "left" }}>
                                    {this.props.t("Before-Starting")}{" "}
                                    </h3>
                                    <div className={"text-left"} style={{
                                        backgroundColor: "#ffffff",
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        paddingRight: 15,
                                    }}>
                                        {this.props.t("Before-Starting-Instructions")}
                                    </div>
                                    <div className={"btn-flex"}
                                         style={{
                                             backgroundColor: "#ffffff",
                                             paddingTop: 30,
                                             paddingBottom: 10,
                                             paddingRight: 15,
                                         }}>
                                        <a href={"http://speedtestcms.eastus.cloudapp.azure.com/application/MaskSelectorToolkitWPF.application"}
                                           className={"btn btn-primary"}>
                                            {this.props.t("Download-MST")}
                                        </a>
                                    </div>
                                    <div className={"btn-flex"}
                                         style={{
                                             paddingRight: 15,
                                             marginBottom: 30,
                                         }}>
                                        <a href={"http://speedtestcms.eastus.cloudapp.azure.com/application/MaskSelectorToolkitWPF.sfv"}>
                                            {this.props.t("Simple-File-Verification")}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default withTranslation()(Clinician);
