import React, { Component } from 'react';
import PropTypes from "prop-types";

import './Lead.scss';
import ClassNames from "classnames";

import HeaderBar from "../HeaderBar";

class Lead extends Component {
    render() {
        const { children, className, heroClassName, majorText, minorText, style } = this.props;

        let classes = ClassNames({
            [ `${className}` ]: className,
            'lead': true
        });

        return (
            <div className={classes} style={{ style }}>
                <div className={heroClassName}>
                    <div className={'container'}>
                        <HeaderBar/>
                        <h1 className='header-text-container'>
                            <span className='x-bold'>{majorText}</span>
                            <span>{minorText}</span>
                        </h1>
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

Lead.defaultProps = {
    heroClassName: 'hero'
};

Lead.propTypes = {
    className: PropTypes.string,
    heroClassName: PropTypes.string,
    majorText: PropTypes.string,
    minorText: PropTypes.string,
    style: PropTypes.object
};

export default Lead;
