import React, { Component } from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class SocialMediaBarItem extends Component {
    render() {
        const { className, icon, href, style } = this.props;

        let classes = ClassNames({
            'icon': true,
            [ `${className}` ]: className
        });

        return (
            <span className={classes} style={style}>
                <a href={href} rel="noopener noreferrer" target="_blank">
                    <FontAwesomeIcon icon={[ 'fab', icon ]} size={'2x'}/>
                </a>
            </span>
        );
    }
}

SocialMediaBarItem.defaultProps = {
    href: 'Connect with Philips',
    className: 'social-media-item'
};

SocialMediaBarItem.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    icon: PropTypes.string.isRequired,
    style: PropTypes.object
};

export default SocialMediaBarItem;
