import axios, { AxiosRequestConfig } from "axios";
import axiosRetry from "axios-retry";

// Axios Retry Config
axiosRetry(axios, { retries: 3 });

export interface IBaseHttpClient {
  request(_url: string, _options: any, _data: any, _method: string): any;
}

export class BaseHttpClient implements IBaseHttpClient {
  async request(url: string, options: any, method: string, data?: any) {
    let headers: any = {};
    let responseType: any = {};

    try {
      if (options.headers) {
        headers = Object.assign(headers, options.headers);
      }

      if (options.responseType) {
        responseType = options.responseType;
      }

      const request = {
        url,
        method,
        data,
        headers,
        responseType,
      } as AxiosRequestConfig;

      const response = await axios(request);

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export const HttpClient = new BaseHttpClient();
