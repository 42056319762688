import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Lead from "../../components/Lead";
import Footer from "../../components/Footer";
import './Home.scss';

class Home extends Component {
    render() {
        return (
            <div>
                <div className={'home'}>
                    <Lead majorText={this.props.t("MaskSelector-Software")} minorText={''} heroClassName={'hero-home'}/>
                    <div className={'site-content'}>
                        <div className={'container'}>
                            <h3>{this.props.t("Home-Instructions")}</h3>
                            <div className={'home-padding-top'}>
                                <a href={'/provider/'} className={'btn btn-primary btn-home'}>
                                {this.props.t("Home-HCP")}
                                </a>
                            </div>
                            <div className={'btn-container'}>
                                <a href={process.env.REACT_APP_DOMAIN_2D} className={'btn btn-primary btn-home'}>
                                {this.props.t("Home-Patient")}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default withTranslation()(Home);
