/**
 * Internationalizing Support Configuration
 * Do not change.
 */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// Locales
import en_US from "./locales/en-US/common";
import da from "./locales/da/common";
import de from "./locales/de/common";
import es from "./locales/es/common";
import fr from "./locales/fr/common";
import ja from "./locales/ja/common";
import ko from "./locales/ko/common";
import nl from "./locales/nl/common";
import nb from "./locales/nb/common";
import pl from "./locales/pl/common";
import pt_BR from "./locales/pt-BR/common";
import pt_PT from "./locales/pt-PT/common";
import sv from "./locales/sv/common";
import zh_CN from "./locales/zh-CN/common";

const resources = {
  en_US: {
    translation: en_US,
  },
  da: {
    translation: da
  },
  de: {
    translation: de
  },
  es: {
    translation: es
  },
  fr: {
    translation: fr
  },
  ja: {
    translation: ja
  },
  ko: {
    translation: ko
  },
  nl: {
    translation: nl
  },
  nb: {
    translation: nb
  },
  pl: {
    translation: pl
  },
  pt_PT: {
    translation: pt_PT
  },
  pt_BR: {
    translation: pt_BR
  },
  sv: {
    translation: sv
  },
  zh_CN: {
    translation: zh_CN
  }
};

// Container Initialization
i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
  resources,
  detection: { caches: [] },
  fallbackLng: {
    default: ['en_US'],
    'pt-PT': ['pt_PT'],
    'pt-BR': ['pt_BR'],
    'zh-CN': ['zh_CN']
  },
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
