import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import Dropdown from 'react-bootstrap/Dropdown';

import './SiteSelector.scss';

class SiteSelector extends Component {
    render() {
        const { className, style } = this.props;

        let classes = ClassNames({
            [ `${className}` ]: className,
            'site-selector': true
        });

        return (
            <div className={classes} style={style}>
                <Dropdown>
                    <Dropdown.Toggle className={'site-selector-button'}>
                        {this.props.t("Site-Selector")}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="https://www.careorchestrator.com" target={'blank'}>Care Orchestrator</Dropdown.Item>
                        <Dropdown.Item href="https://www.sleepapnea.com" target={'blank'}>SleepApnea</Dropdown.Item>
                        <Dropdown.Item href="http://www.dreammapper.com" target={'blank'}>DreamMapper</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }
}

SiteSelector.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
};

export default withTranslation()(SiteSelector);
