import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import Image from '../Image';

import shieldWhite from '../../assets/images/Shield_White_2014.svg';
import shieldBlue from '../../assets/images/Shield_RGB_2014.svg';
import shieldBlack from '../../assets/images/Shield_Black_2014.svg';
import shieldLightBlue from '../../assets/images/Shield_CMYK_2014.svg';
import shieldPhilipsBlue from '../../assets/images/Shield_PMS_2014.svg';

import './Shield.scss';

class Shield extends Component {
    render() {
        const { className, color, height, style, width } = this.props;

        let shield = shieldPhilipsBlue;

        switch (color) {
            case 'black':
                shield = shieldBlack;
                break;
            case 'blue':
                shield = shieldBlue;
                break;
            case 'lBlue':
                shield = shieldLightBlue;
                break;
            case 'philips':
                shield = shieldPhilipsBlue;
                break;
            default:
                shield = shieldWhite;
                break;
        }

        let classes = ClassNames({
            [ `${className}` ]: className
        });

        return (
            <div className={classes} style={style}>
                <Image alt={'Philips'} className={'img-fluid'} height={height} src={shield} width={width}/>
            </div>
        )
    }
}

Shield.defaultProps = {
    className: 'shield',
    color: 'white'
};

Shield.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.number,
    style: PropTypes.object,
    width: PropTypes.number
};


export default Shield;
